<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách hành trình</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'm']" @shortkey="add()" @click="add()" class="md-raised md-primary btn-add">Thêm <span>m</span>ới<md-tooltip>Thêm mới (Ctrl + T)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                   <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái]</label>
                                <label v-else for="status">Trạng thái</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="0">Chưa xử lý</md-option>
                                    <md-option value="1">Đang xử lý</md-option>
                                    <md-option value="2">Đã hoàn thành</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo mã</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                   </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th style="width:10%;">Đơn hàng</th>
                                <th style="width:10%;">Kiện hàng</th>
                                <th style="width:20%;">Tên hàng</th>
                                <th class="left" style="width:25%;">Nơi đi</th>
                                <th class="left" style="width:20%;">Nơi đến</th>
                                <th style="width:10%;">Điều xe</th>
                                <th style="width:10%;">Trạng thái</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">{{item.order.orderCode}}</td>
                                <td class="center">{{item.orderDetailCode}}</td>
                                <td class="center">{{item.goods.goodsName}}</td>
                                <td class="left">{{item.placeOfGo.locationName}}</td>
                                <td class="left">{{item.placeOfCome.locationName}}</td>
                                <td class="center">
                                    <md-chip v-if="item.jobId == 0" class="status status-inprogress">Chưa điều xe</md-chip>
                                    <div v-else >
                                        <md-chip @click="gotoJob(item.jobId)" class="clickable status status-blue">Đã điều xe</md-chip>
                                        <md-tooltip>Click xem lệnh điều động phương tiện</md-tooltip>
                                    </div>
                                    
                                </td>
                                <td class="center">
                                    <md-chip v-if="item.status == 0" class="status status-inprogress">Chưa thực hiện</md-chip>
                                    <md-chip v-else-if="item.status == 1" class="status status-inprogress">Đang thực hiện</md-chip>
                                    <md-chip v-else-if="item.status == 2" class="status status-blue">Hoàn thành chặng</md-chip>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                 <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import orderService from '../../api/orderService';
    import messageBox from '../../utils/messageBox';
    import common from '../../mixins';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Danh sách hành trình'
        },
        data() {
            return {
               loadding: false,
               search: { pageIndex: 1, pageSize: common.pageSize, status: '', code: '' },
               pager: { totalPages: 1 },
               data: [],
               rowPerPageOptions: [],
               id: 0,
               status: [],
               selectedAllStatus: false
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.query.status != undefined){
               this.search.status = this.$route.query.status;
               this.status = this.$route.query.status.split(',')
            }
            else{
                this.status = [];
            }
            if(this.$route.query.orderId != undefined){
               this.search.orderId = this.$route.query.orderId;
            }
            this.filter();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['0','1','2'];
                }
                else{
                    this.status = [];
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.status = '';
                this.status = [];
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + "&code=";
                this.$router.push('/goods-routes?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + this.search.status + "&code=" + this.search.code;
                this.$router.push('/goods-routes?' + url).catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                orderService.getRoutes(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },   

            gotoJob(jobId){
                this.$router.push('/job/view/' + jobId);
            }
        },
        watch: {
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 4){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
                this.filter();
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.orderId': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
